:root{
  --dark-red:#1334d1;
  --dark-blue:#072639;
  --dark-grey: #4a4747;

}

.text-dark-red{
  color: var(--dark-red) !important;
}

.bg-dark-red{
  background-color: var(--dark-red) !important;
}

.border-dark-red{
  border-color: var(--dark-red) !important;
}

.nav-bg-darkblue{
	background-color: var(--dark-blue) !important;
}

.btn-disabled-grey {
	background-color: var(--dark-grey) !important
  }
.border-dark-grey {
	border-color: var(--dark-grey) !important
}

/* new line */

body {
	font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji",
		"Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
	box-sizing: border-box;
}

@import url("https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css");
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.3.0/font/bootstrap-icons.css");

.parent {
	padding: 3.5rem 2.5rem;
}

.parent.button_screen {
	padding-bottom: 0;
}

.parent.buttons_page {
	padding-bottom: 16px;
}

a {
	text-decoration: none;
}

.main-logo {
	height: 60px;
	cursor: pointer;
	/* margin-top: -5px; */
}


.navigate-img {
	height: 30px;
	cursor: pointer;
	/* margin-right: 30px; */
}

.header-container {
	background-color: rgb(90, 40, 125);
	display: flex;
	align-items: center;
	padding: 1.5rem 2.5rem;
	margin: -3.5rem -2.5rem 38px;
	height: 98px;
	position: relative;
	z-index: 2;
}

.main-container {
	margin-top: 3rem;
	display: flex;
	justify-content: space-evenly;
	flex-wrap: wrap;
	height: 45vh;
	align-items: center;
	position: relative;
}

.main-container2 {
	/* margin-top: 3rem; */
	margin-top: 5px;
	display: flex;
	/* justify-content: center; */
	margin-left: 0px;
	flex-wrap: wrap;
	/* height: 33vh; */
	/* align-items: center; */
	position: relative;
	z-index: 3;
	width: 275px;
}

.hide-sidebar-border {
	border: none !important;
}

.main-container3 {
	margin-top: 3rem;
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	height: 20vh;
	align-items: center;
	position: relative;
}

.container-left {
	/* margin-left: 20%; */
	font-size: 25px;
	width: 300px;
	/* flex-basis: 25%; */
}

.container-middle {
	border-left: 3px solid rgb(0, 106, 77);
	flex-basis: 0.01%;
	height: 75%;
	margin-top: 10px;
}

.header_quotation {
	color: #fff;
	margin: 0 0 0 1rem;
	padding: 4px 10px;
	position: relative;
	/* max-width: 35%; */
	width: 300px;
	line-height: 1.3;
}

.header_quotation:before {
	background-color: #fff;
	content: "";
	position: absolute;
	left: -25px;
	width: 2px;
	height: 50px;
	margin-top: -4px;
}

.header_quotation+.header_quotation {
	margin-left: 0;
}

.header-logos {
	display: flex;
	/* flex-direction: row; */
	/* justify-content: space-between; */
	/* align-items: center; */
}

.header_quotation .diro-logo-trans {
	position: relative;
	bottom: 2px;
	width: 32px;
}

.sidebar_container_wrapper {
	display: flex;
	/* justify-content: space-between; */
}

.button_screen .sidebar_container_wrapper {
	margin: -20px -42px 0;
}

.submission_id_wrap {
	position: relative;
}

.container-right {
	font-size: 25px;
	/* flex-basis: 25%; */
	width: 300px;
}

.font-green {
	font-weight: 700;
	color: rgb(0, 106, 77);
	text-decoration: underline;
}

.input-form {
	width: 100%;
	margin-top: 3rem;
	position: relative;
}

.input-box {
	width: calc(100% - 38px);
	font-weight: 600;
	font-size: 15px;
	padding: 10px 15px;
	height: 46px;
	border-radius: 8px;
	border: 2px solid rgb(90, 40, 125);
	box-sizing: border-box;
}

.input-box:focus {
	outline: none !important;
	border: 2px solid rgb(90, 40, 125);
}

.input-btn {
	cursor: pointer;
	position: absolute;
	right: 0px;
	top: 0;
	width: 50px;
	height: 46px;
	/* width: 40px; */
	font-weight: 600;
	font-size: 15px;
	padding: 2px 15px;
	border-radius: 8px;
	color: white;
	background: rgb(90, 40, 125);
	border: 0;
	display: flex;
	text-align: center;
	justify-content: center;
	align-items: center;
}


.loader-spinner {
	position: absolute !important;
	right: 10px;
}

.diro-img-container {
	display: flex;
	/* justify-content: center; */
	align-items: center;
	/* width: 100px; */
	text-decoration: none;
	font-size: 14px;
	font-weight: 500;
	color: black;

	height: 30px;
	position: absolute;
	right: 0;
	bottom: -20px;
	/* position: fixed;
	right: 3%;
	bottom: 3%; */
}

.diro-img {
	width: 31px;
	/* margin-left: 165px; */
	float: right;
	margin-left: 3px;
	margin-top: -2px;
}

.confirmation-box {
	/* width: 30%; */
	font-size: 25px;
	padding: 3rem;
	border: 3px solid rgb(0, 106, 77);
	border-radius: 40px;
	box-sizing: border-box;
	max-width: 555px;
}

.confirmation-btn-container {
	margin-top: 3rem;
	display: flex;
	justify-content: space-between;
	/* gap: 24px; */
}

.confirmation-btn-container2 {
	margin-top: 3rem;
	display: flex;
	justify-content: center;
}

.confirmation-btn {
	cursor: pointer;
	min-width: 200px;
	font-weight: 600;
	font-size: 15px;
	height: 50px;
	padding: 15px;
	border-radius: 8px;
	color: white;
	background: rgb(0, 106, 77);
	border: 0;
	width: calc(50% - 16px);
}

.verification-btn-container {
	margin-top: -15px;
	display: flex;
	flex-direction: column;
	position: relative;
}

.verification-btn {
	margin: 1rem 0;
	cursor: pointer;
	width: 270px;
	font-weight: 700;
	font-size: 15px;
	padding: 15px;
	border-radius: 8px;
	color: white;
	background: rgb(90, 40, 125);
	border: 0;
	letter-spacing: 0.1px;
	height: 55px;
	margin-top: 70px;
}

.resubmit-btn {
	margin: 1rem;
	cursor: pointer;
	width: 100px;
	font-weight: 600;
	font-size: 15px;
	padding: 15px;
	border-radius: 8px;
	color: white;
	background: rgb(5, 109, 174);
	border: 0;
	letter-spacing: 0.1px;
	height: 55px;
	display: none;
	position: absolute;
	left: -90px;
}

.list-btn {
	position: relative;
	display: flex;
	align-items: center;
}

.verification-btn-disabled {
	margin: 1rem;
	width: 270px;
	font-weight: 700;
	font-size: 15px;
	padding: 15px;
	border-radius: 8px;
	color: #444;
	background: rgb(217, 217, 217);
	border: 0;
	letter-spacing: 0.1px;
	height: 55px;
	/* pointer-events: none; */
}

.verification-btn-disabled:hover+.resubmit-btn,
.resubmit-btn:hover {
	display: inline-block;
}

.verification-btn-disabled:hover+.resubmit-btn,
.resubmit-btn:hover {
	animation: buttonAnimy 0.35s forwards;
	-webkit-animation: buttonAnimy 0.35s forwards;
}

@keyframes buttonAnimy {
	from {
		opacity: 0;
		left: -70px;
	}

	to {
		opacity: 1;
		left: -90px;
	}
}

@-webkit-keyframes buttonAnimy {
	from {
		opacity: 0;
		left: -70px;
	}

	to {
		opacity: 1;
		left: -90px;
	}
}

.id-bg {
	background-color: rgb(217, 217, 217);
	border-radius: 15px;
	padding: 10px 20px;
	margin-right: 10px;
	height: 145px;
}

.id-bg2 {
	background-color: rgb(217, 217, 217);
	border-radius: 15px;
	padding: 10px 20px;
	margin-right: 10px;
	/* height: 145px; */
}

.id-bg-text {
	margin-top: 15px;
	margin-bottom: 20px;
	margin-left: 5px;
	font-size: 18px;
	font-weight: 700;
}

.submission-id-container {
	margin-left: 3rem;
}

.submission-id-content {
	display: flex;
	align-items: flex-end;
	position: relative;
}

.submission-text {
	width: 360px;
	margin-left: 4px;
	font-size: 14px;
	margin-top: 15px;
	font-weight: 700;
	position: absolute;
	line-height: 1.3;
	z-index: 4;
}

.copy-img {
	height: 32px;
	cursor: pointer;
}

.copy-container {
	position: relative;
	top: -10px;
}

.copy-success {
	position: absolute;
	top: -35px;
	width: 60px;
	background-color: rgba(94, 190, 99, 0.486);
	padding: 5px;
	border-radius: 5px;
	display: flex;
	justify-content: center;
	align-items: flex-end;
	font-size: 12px;
	font-weight: 500;
	margin-bottom: 5px;
}

/* .iframe {
	height: 100vh;
	width: 100vw;
} */
.combine-img {
	height: 150px;
	margin-top: -2px;
}

.processing-text {
	margin-top: 20px;
	font-size: 12px;
}

.invalid-email-text {
	font-size: 15px;
	color: red;
	position: absolute;
    margin-top: 47px;
	left: 6px;
}

/* .id-bg::after {
	content: " ";
	display: block;
	position: absolute;
	height: 2.7px;
	width: 15px;
	left: 43%;
	top: 50%;
	background: #222;
} */
.add_more_bank {
	margin-top: 0;
	position: relative;
	gap: 64px;
	/* width: 326px; */
	/* text-align: center; */

	/* margin-left: 300px; */

	margin-bottom: 60px;
	height: auto;
	position: relative;
	top: 8px;
	z-index: 1;
}

.add_more_bank_button {
	/* position: absolute; */
	/* margin-left: 300px; */
	background-color: transparent;
	border: 1px solid black;
	border: 0;
	/* color: rgb(0, 106, 77); */
	color: #0d6efd;
	cursor: pointer;
	text-decoration: underline;
	/* padding: 4px 10px; */
	font-size: 15px;
	font-weight: 500;
	/* padding-bottom: 35px; */
	margin-top: -40px;
	margin-right: 24px;
	left: 10%;
	/* margin-top: 5rem; */
	/* margin-bottom: 3rem; */
}

.add-space {
	/* width: 100px; */
	margin-right: 62px;
}

.add-space2 {
	/* width: 100px; */
	margin-right: 86px;
}

.extra_bank {
	height: auto;
	margin-top: 36px;
	z-index: 5;
}

.margin-top {
	margin-top: 7rem;
}

.submission_id_wrap {
	width: 32%;
	/* min-height: calc(100vh - 170px); */
}

.faq .accordion-header,
.accordion-header {
	font-size: 14px;
	margin: 0;
	padding: 0;
}

.iframe_wrapper {
	height: 91vh;
	overflow: hidden;
}

.iframe_wrapper .header-container {
	margin: 0;
}

/* Copied css from offshore */

.overflow-hide {
	overflow: hidden;
}

.fullscreen#sidebar {
	height: 100vh;
	max-height: none;
}

#sidebar .accordion-button {
	font-size: 15px;
	padding: 6px 0 !important;
}

div.iframe_wrapper #sidebar {
	position: absolute;
	z-index: 999;
	left: 0;
	padding-left: 10px;
}

.header-container~#sidebar {
	height: calc(100vh - 130px);
}

div.iframe_wrapper .header-container~#sidebar {
	top: 98px;
	height: calc(100vh - 98px);
	max-height: calc(100vh - 98px);
}

div.iframe_wrapper .header-container~#sidebar.collapsed {
	border-right: solid 2px #000;
	width: 56px;
}

div.iframe_wrapper .header-container~#sidebar {
	border-right: solid 2px #000;
}

div.iframe_wrapper #sidebar.fullscreen {
	height: 100vh;
	max-height: 100vh;
}

#sidebar::-webkit-scrollbar {
	width: 4px;
	background-color: #e0e0e0;
}

#sidebar::-webkit-scrollbar-thumb {
	background-color: #707070;
}

#sidebar.collapsed {
	width: 20px;
	height: 91vh;
	padding-right: 44px;
}
@media (min-width:1800px) {
	#sidebar.collapsed{
		height: 93.5vh;
	}
	.iframe_wrapper {
		height: 93.5vh;
	}
}

#sidebar.collapsed .content {
	display: none;
	height: 100vh;
}

/* .content {
	margin-top: -5px;
} */
#sidebar.collapsed #toggle2 {
	margin-bottom: 15px;
}

.accordion-collapse {
	max-height: 900px;
	transition: max-height 0.8s ease-in;
	overflow: hidden;
}

.accordion-collapse.collapse-box {
	max-height: 0;
	transition: max-height 0.25s ease-out;
	/* transition: none; */
}

#iframe-container {
	margin-left: 72px;
	height: 100%;
	/* overflow: auto; */
	/* width: 100% !important; */
	/* float: left; */
	transition: width 0.35s;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
}

#sidebar+#iframe-container {
	/* width: 93vw; */
	width: 100%;

	width: calc(100% - 72px);
	margin-left: 72px;
}

#sidebar.collapsed+#iframe-container {
	/* width: 93vw; */
	width: 100%;

	width: calc(100% - 72px);
	margin-left: 72px;
}

#sidebar>div {
	/* width: calc(100% - 20px); */
	width: 100%;
}

#sidebar .nav-btn+div {
	width: 100%;
	margin-left: 25px;
}

#sidebar .nav-btn+div .collumn-faq .overflow {
	border-right: 0;
}

#sidebar>.nav-btn {
	width: calc(100% - 28px);
	margin: 10px 0;
}

.heading h2,
.faq h2 {
	padding-top: 5px;
	margin-bottom: 16px;
	font-size: 1.4rem;
	font-size: 18px;
	font-weight: 700;
}

.faq h2 {
	margin-bottom: 12px;
}

.main-container::backdrop {
	background-color: rgba(255, 255, 255, 0);
}

#toggle {
	background: none;
	outline: none;
	/* float: right; */
	border: none;
	font-size: 35px;
	/* margin-left: 20px; */
	padding: 0;
	margin-left: -1px;
}

.collapsed #toggle {
	margin-left: -1px;
}

#toggle3 {
	background: none;
	outline: none;
	/* float: right; */
	border: none;
	font-size: 31px;
	/* margin-left: 20px; */
	padding: 0;
	margin-bottom: 9px;
	margin-left: -4px;
}

.hide-toggle3 {
	display: none;
}

.active-toggle3 {
	display: block;
}

#icon1 {
	margin-right: -17px;
	display: flex;
}

#icon2 {
	display: flex;
}

.nav-btn {
	/* width: 106%; */
	/* padding-left: 2px; */
	display: flex;
	justify-content: space-between;
	margin-bottom: 20px;
	flex-wrap: wrap;
	align-items: center;
}

#sidebar:not(.collapsed) .nav-btn {
	justify-content: flex-end;
}

.collapsed .nav-btn {
	justify-content: space-between;
}

#toggle2 {
	background: none;
	outline: none;
	/* float: left; */
	/* margin-left: -8px; */
	/* background: #d6d6d6; */
	border: none;
	font-size: 26px;
	padding: 0;
	margin-top: 4px;
}

#iframe-container {
	/* height: 100vh; */
	/* width: 67vw; */
	width: 100%;
}

.iframe {
	position: relative;
	height: 100vh;
	width: 100vw;
}

/* Older .header-container ~ .iframe {
	height: calc(125vh - 58px);
	width: 120vw;
	transform: scale(0.8);
	left: calc(-10vw + 28px);
	top: calc(-12vh + 4px);
} */
/* .header-container ~ .iframe {
	height: calc(117vh - 72px);
	width: calc(124% - 55px);
	transform: scale(0.8);
	right: calc(12% - 54px);
	top: calc(-12vh + 10px);
	background-color: #e5e5e5;
	box-sizing: border-box;
} */
.header-container~.iframe {
	height: calc(120% - 78px);
	width: calc(120% + 4px);
	transform: scale(0.8);
	right: calc(10% - 26px);
	bottom: calc(10% + 10px);
	background-color: #e5e5e5;
	box-sizing: border-box;
}

.fullscreen+.iframe {
	width: calc(100vw - 62px);
	margin-left: 62px;
}

.faq {
	/* margin-top: 16px; */
	margin-bottom: 8px;
}

.faq~p {
	line-height: 136%;
	margin-bottom: 0.8rem;
}

.terms {
	font-size: 14px;
	margin-top: 5px;
	/* width: 250px; */
}

.accordion-body {
	padding: 0 !important;
	margin-top: 10px !important;
	margin-bottom: 10px !important;
	font-size: 14px !important;
	line-height: 20px;
	text-align: justify;
}

.plus-minus {
	height: 26px;
	margin-right: 10px;
}

.accordion-button {
	padding: 8px 0 !important;
	/* display: inline-block !important; */
	display: flex;
	justify-content: center;
	align-items: center;
}

ul li {
	padding: 0 !important;
	margin-bottom: 5px;
}

.accordion-header i {
	margin-right: 10px;
}

/* .button .accordion-button .collapsed::after {
	float: left !important;
} */
.arrow {
	border: solid black;
	border-width: 0 2px 2px 0;
	display: inline-block;
	padding: 3px;
	/* float: right; */
	transition: 0.13s ease-in-out;
}

.up {
	margin-top: 7px;
	transform: rotate(-135deg);
	-webkit-transform: rotate(-135deg);
}

.down {
	margin-top: -2px;
	transform: rotate(45deg);
	-webkit-transform: rotate(45deg);
}

.submit-button {
	/* margin: 1rem; */
	margin-top: 20px;
	cursor: pointer;
	width: 140px;
	font-weight: 700;
	font-size: 15px;
	padding: 10px;
	border-radius: 8px;
	color: white;
	background: rgb(5, 109, 174);
	border: 0;
	letter-spacing: 0.1px;
	height: 50px;
	/* position: absolute; */
	/* bottom: -80px;
	right: -15px; */
}

.support-input {
	padding: 10px;
	height: 151px;
	/* margin-top: -14px; */
	width: 300px;
	margin-left: 30px;
	border: 1px solid rgba(0, 0, 0, 0.125);
}

.support-input:hover {
	border: 1px solid rgb(171, 171, 171) !important;
}

.support-input:focus,
.support-input:focus-visible {
	outline: none;
}

.support-email:hover {
	border: 1px solid rgb(171, 171, 171) !important;
}

.support-email:focus,
.support-email:focus-visible {
	outline: none;
}

.support-email {
	width: 300px;
	padding: 8.7px;
	margin-left: 30px;
	margin-bottom: 10px;
	border: 1px solid rgba(0, 0, 0, 0.125);
}

.text-area-container {
	display: flex;
	flex-direction: column;
	/* position: relative; */
}

.accordion-button::after {
	margin-top: -1px;
	margin-right: 10px;
	color: aqua !important;
	background-image: none !important;
	transform: scale(0.7) !important;
	float: left !important;
}

.accordion-button:not(.collapsed)::after {
	background-image: none;
}

.bg-blur {
	align-items: top;
	background: rgba(3, 3, 3, 0.6);
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 1000;
}

.support-container {
	background-color: rgb(247, 247, 247);
	/* max-height: 75%; */
	width: 671px;
	justify-content: center;
	position: relative;
	margin: 11% auto 0;
	overflow: auto;
	height: auto;
	display: block;
}

.support-container h4 {
	text-align: center;
	top: 30px;
	right: 0;
	left: 0;
	margin-left: auto;
	margin-right: auto;
	/* padding-top: 30px; */
	height: 70px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 2px;
}

.accordion-container {
	/* margin-top: 20px; */
	margin-bottom: 20px;
	padding: 15px;
	padding-top: 0;
	display: flex;
	justify-content: center;
}

.shadow-box {
	display: flex;
	align-items: center;
	padding: 5px !important;
	border-radius: 5px;
	font-size: 15px;
	/* box-shadow: 0 1px 2px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 1px rgba(0, 0, 0, 0.12) !important; */
	/* border-radius: 5px !important; */
	transition: all 0.3s ease 0s !important;
	margin-bottom: 2px !important;
	cursor: pointer;
}

.shadow-box:hover {
	box-shadow: 0px 3px 8px rgba(43, 43, 43, 0.153) !important;
}

.exit-screen-input {
	margin-right: 10px;
	margin-top: 2px;
}

.accItem {
	margin-bottom: 10px;
	/* border: none !important; */
	/* border: 1px solid #0000 !important; */
	box-shadow: none !important;
}

.accItem2 {
	font-size: 14px !important;
	box-shadow: none !important;
	border-radius: 5px !important;
}

.accItem:hover {
	border: 1px solid rgb(171, 171, 171) !important;
}

.accordion-item2 {
	width: 316px;
	background-color: #fff;
	border: 1px solid rgba(0, 0, 0, 0.125);
	/* border-top-left-radius: 0.25rem;
	border-top-right-radius: 0.25rem; */
}

.accordion-item2:hover {
	border: 1px solid #ababab;
}

.accordion-button2 {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	padding: 0.7rem 1.25rem;
	font-size: 1rem;
	font-weight: 600;
	color: #212529;
	text-align: left;
	background-color: #fff;
	border: 0;
	border-radius: 0;
	overflow-anchor: none;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
		border-radius 0.15s ease;
}

.accordion-body2 {
	display: flex;
	align-items: center;
	padding: 0 1.25rem !important;
	font-size: 14px !important;
	line-height: 20px;
	text-align: justify;
}

.thankyou-screen {
	font-weight: 500;
	/* margin-top: 10%; */
	height: 307px;
	display: flex;
	justify-content: center;
	align-items: center;
	background: rgb(247, 247, 247);
	text-align: center;
	/* width: 671px; */
	width: 100%; 
    max-width: 671px; 
    margin: 0 auto;
    padding: 20px; 
}
@media (max-width: 768px) {
    .thankyou-screen {
        width: 90%;
    }
}

@media (max-width: 480px) {
    .thankyou-screen {
        padding: 10px; 
    }
}

.close {
	position: absolute;
	right: 32px;
	top: 32px;
	width: 32px;
	height: 32px;
	opacity: 0.3;
}

.close:hover {
	opacity: 1;
}

.close:before,
.close:after {
	position: absolute;
	left: 15px;
	content: " ";
	height: 33px;
	width: 2px;
	background-color: #333;
}

.close:before {
	transform: rotate(45deg);
}

.close:after {
	transform: rotate(-45deg);
}

.close2 {
	position: absolute;
	right: 20px;
	top: 20px;
	width: 32px;
	height: 32px;
	opacity: 0.5;
	cursor: pointer;
}

.close2:hover {
	opacity: 1;
}

.close2:before,
.close2:after {
	position: absolute;
	left: 15px;
	content: " ";
	height: 15px;
	width: 3px;
	background-color: #111;
}

.close2:before {
	transform: rotate(45deg);
}

.close2:after {
	transform: rotate(-45deg);
}

.support_button {
	/* margin: 1rem; */
	/* margin-top: 100px; */
	margin-top: 60px;
	cursor: pointer;
	width: 270px;
	font-weight: 700;
	font-size: 15px;
	padding: 7px;
	border-radius: 8px;
	color:#1334d1;
	background: none;
	display: inline-block;
	border: 2px solid #1334d1;
	letter-spacing: 0.1px;
	/* height: 3rem; */
	/* text-align: center; */
}

.main-container2:first-child+.main-container2 .support_button,
.main-container2:first-child+.add_more_bank+.main-container2 .support_button {
	margin-top: 100px;
}

.main-container2:first-child+.extra_bank+.main-container2 .support_button {
	margin-top: 60px;
}

.support-error-msg {
	color: red;
	margin-top: 15px;
	font-size: 14px;
	display: flex;
	/* justify-content: center; */
	align-items: center;
	margin-right: 10px;
}

/* Loader */
/* Absolute Center Spinner */
.loading {
	position: fixed;
	z-index: 999;
	height: 2em;
	width: 2em;
	overflow: show;
	margin: auto;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	visibility: hidden;
}

/* Transparent Overlay */
.loading:before {
	content: "";
	display: block;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(255, 255, 255, 0.938);
}

/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
	/* hide "loading..." text */
	font: 0/0 a;
	color: transparent;
	text-shadow: none;
	background-color: transparent;
	border: 0;
}

.loading:not(:required):after {
	content: "";
	display: block;
	font-size: 10px;
	width: 1em;
	height: 1em;
	margin-top: -0.5em;
	-webkit-animation: spinner 1500ms infinite linear;
	-moz-animation: spinner 1500ms infinite linear;
	-ms-animation: spinner 1500ms infinite linear;
	-o-animation: spinner 1500ms infinite linear;
	animation: spinner 1500ms infinite linear;
	border-radius: 0.5em;
	-webkit-box-shadow: rgba(0, 0, 0, 0.75) 1.5em 0 0 0, rgba(0, 0, 0, 0.75) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) 0 1.5em 0 0,
		rgba(0, 0, 0, 0.75) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.5) -1.5em 0 0 0, rgba(0, 0, 0, 0.5) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.75) 0 -1.5em 0 0,
		rgba(0, 0, 0, 0.75) 1.1em -1.1em 0 0;
	box-shadow: rgba(0, 0, 0, 0.75) 1.5em 0 0 0, rgba(0, 0, 0, 0.75) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) 0 1.5em 0 0,
		rgba(0, 0, 0, 0.75) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) -1.5em 0 0 0, rgba(0, 0, 0, 0.75) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.75) 0 -1.5em 0 0,
		rgba(0, 0, 0, 0.75) 1.1em -1.1em 0 0;
}

/* Animation */

@-webkit-keyframes spinner {
	0% {
		-webkit-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-ms-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	100% {
		-webkit-transform: rotate(360deg);
		-moz-transform: rotate(360deg);
		-ms-transform: rotate(360deg);
		-o-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@-moz-keyframes spinner {
	0% {
		-webkit-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-ms-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	100% {
		-webkit-transform: rotate(360deg);
		-moz-transform: rotate(360deg);
		-ms-transform: rotate(360deg);
		-o-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@-o-keyframes spinner {
	0% {
		-webkit-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-ms-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	100% {
		-webkit-transform: rotate(360deg);
		-moz-transform: rotate(360deg);
		-ms-transform: rotate(360deg);
		-o-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@keyframes spinner {
	0% {
		-webkit-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-ms-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	100% {
		-webkit-transform: rotate(360deg);
		-moz-transform: rotate(360deg);
		-ms-transform: rotate(360deg);
		-o-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

.fullscreen-nav {
	margin-top: 20px;
	margin-bottom: 20px;
}

.logo_wrap {
	display: block;
}

.iframe_wrapper div#sidebar {
	max-height: calc(100vh - 58px);
	border-right: 2px solid #1334d1;
	
}

#sidebar .accordion-button:focus,
#sidebar .accordion-button:active {
	box-shadow: none;
}

.submission_id_wrap .footer {
	position: -webkit-sticky;
	position: sticky;
	top: 100vh;
	/* width: 190px; */
	/* margin-left: calc(100vw - 211px); */
	/* margin-top: 80px; */
	margin-top: 0;
	padding: 28px 4px 0;
	height: 50px;
	display: flex;
	justify-content: flex-end;
}

.extra_bank+.footer {
	margin-top: 0;
}

.add_more_bank+.footer {
	margin-top: 60px;
}

.submission_id_wrap .footer .diro-img-container {
	position: static;
}

.accordion-item {
	border: 0;
}

.heading p {
	line-height: 136%;
	margin-bottom: 0.8rem;
}

.get_supp_btn_space {
	width: 315px;
	margin-left: 3rem;
}

.get_supp_btn_wrap {
	width: 270px;
}

.overflow-hide {
	overflow: hidden;
}

/* Loader CSS starts */
.loader_super_wrapper {
	background-color: #bcbcbc;
	height: 100vh;
	width: 100vw;
}

.loader_wrapper {
	position: absolute;
	left: calc(50vw - 157px);
	top: calc(50vh - 70px);
	display: flex;
	vertical-align: middle;
	width: 315px;
	height: 160px;
	background-color: #fff;
	border-radius: 8px;
	border: 1px solid rgba(0, 0, 0, 0.125);
	box-shadow: 2px 3px 5px rgb(202, 202, 202, 0.15);
	background-color: #f7f7f7;
	flex-direction: column;
	justify-content: center;
	font-family: "Montserrat", sans-serif;
	gap: 5px;
	letter-spacing: 0.7px;
}

.loader_wrapper>.card-info {
	width: 100%;
}

.loader_wrapper>div {
	margin: 0 auto;
}

.loader_wrapper .card-title {
	font-size: 20px;
	font-weight: 600;
	margin: 0;
	line-height: 30px;
}

.loader_wrapper .mb-3 {
	margin-bottom: 1rem;
}

.lds-ellipsis {
	display: inline-block;
	position: relative;
	width: 78px;
	height: 60px;
}

.lds-ellipsis div {
	position: absolute;
	top: 33px;
	width: 10px;
	height: 10px;
	border-radius: 50%;
	background: #000;
	animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
	left: 8px;
	animation: lds-ellipsis1 0.7s infinite;
}

.lds-ellipsis div:nth-child(2) {
	left: 8px;
	animation: lds-ellipsis2 0.7s infinite;
}

.lds-ellipsis div:nth-child(3) {
	left: 32px;
	animation: lds-ellipsis2 0.7s infinite;
}

.lds-ellipsis div:nth-child(4) {
	left: 56px;
	animation: lds-ellipsis3 0.7s infinite;
}

.iframeWrap {
	width: 100%;
	height: 100vh;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 2;
	margin: 0;
	border: 0;
}

.if_wrap {
	position: relative;
}

.ifr_opacity {
	opacity: 0;
}

.outerLoadIframe {
	margin: 298px 0 0 -2px;
	top: 0;
}

@keyframes lds-ellipsis1 {
	0% {
		transform: scale(0);
	}

	100% {
		transform: scale(1);
	}
}

@keyframes lds-ellipsis3 {
	0% {
		transform: scale(1);
	}

	100% {
		transform: scale(0);
	}
}

@keyframes lds-ellipsis2 {
	0% {
		transform: translate(0, 0);
	}

	100% {
		transform: translate(24px, 0);
	}
}

/* Loader CSS ends */

/* for Fraud.net */
#sidebar {
	/* flex-basis: 30%; */
	/* padding: 10px 0 0 0; */
	/* border-right: 2px solid #006a4c; */
	/* width: calc(58vw + 42px); */
	width: 55%;
	background: #fff;
	/* float: left; */

	/* position: absolute;
	z-index: 999; */
	font-size: 15px;
}

#sidebar .overflow {
	/* overflow: auto;
	transition: width 0.35s;
	scrollbar-width: thin !important;
	max-height: calc(90vh - 170px); */
	overflow: auto;
	background: #fff;
	/* float: left; */
	transition: width 0.35s;
	scrollbar-width: thin !important;
	height: 100%;
	font-size: 15px;
	margin: 0;
	padding-right: 30px;
	border-right: solid 2px #000;
}

#sidebar ::-webkit-scrollbar {
	height: 2px;
	width: 2px;
	background: #fff;
}

#sidebar ::-webkit-scrollbar-thumb {
	background: #393812;
	-webkit-border-radius: 1ex;
	-webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
}

#sidebar ::-webkit-scrollbar-corner {
	background: #000;
}

.collumn-faq {
	/* width: calc(50% - 45px); */
	width: 100%;
	padding-left: 35px;
	padding-right: 60px;
	border-right: solid 2px rgb(90, 40, 125);
	position: relative;
}

.collumn-faq+.collumn-faq {
	padding: 0 0 0 30px;
	width: calc(50% + 20px);
	border: 0;
}

.content_inner {
	min-height: 320px;
	max-height: calc(100vh - 165px);
	padding: 25px 0;
	width: 100%;
}

.heading h2,
.faq h2 {
	padding-top: 5px;
	margin-bottom: 5px;
	/* font-size: 1.4rem; */
	/* font-size: 19px; */
	/* font-weight: 700; */
	font-size: 24px;
	font-weight: 340;
}

.faq h2 {
	margin-bottom: 20px;
}

.heading>h2,
.faq>h2 {
	min-height: 52px;
}

.collumn-faq p {
	line-height: 140%;
	/* margin-bottom: 0.8rem; */
	margin-bottom: 25px;
	font-size: 16px;
	font-weight: 350;
	/* text-align: justify; */
}

.faq.overflow p {
	margin-top: 16px;
	text-align: inherit;
}

.accordion-body {
	padding: 0 !important;
	margin-top: 10px !important;
	margin-bottom: 10px !important;
	font-size: 14px !important;
	line-height: 20px;
	text-align: justify;
}

p.sticky_para {
	margin-bottom: 5px;
	position: sticky;
	top: 100%;
}

.faq_quote {
	max-height: 320px;
	height: 100%;
	position: relative;
}

.iframe_wrapper #sidebar {
	transition: width 0.25s;
}

#sidebar:not(.collapsed)+.iframe:before {
	content: "";
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 10;
}

#sidebar:not(.collapsed) {
	overflow: hidden;
	height: 100vh;
	width: 37%;
}

.iframe_wrapper #sidebar .support_button {
	margin: 18px 0 30px;
}

.iframe_wrapper .content_inner {
	min-height: 356px;
}

/* for Fraud.net ends */

.support_btn_wrapper {
	display: flex;
	justify-content: flex-end;
	text-align: right;
}

/* Browser and OS specific css starts */
.Safari .support_button {
	margin-left: -3px !important;
}

.Safari .support_btn_wrapper {
	justify-content: flex-end;
}

.Safari #toggle {
	min-width: 28px;
}

.Firefox .confirmation-btn,
.Firefox .resubmit-btn,
.Firefox .verification-btn-disabled,
.Firefox .support_button {
	padding: 14px 15px 16px;
}

.Firefox .input-btn {
	height: 47px;
	padding: 1px 15px 3px;
}

/* Browser and OS specific css starts end */
.foot-logo-wrap {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	margin-top: 60px;
	padding: 15px 0 0;
	max-width: 265px;
	margin-left: auto;
	margin-right: auto
}

.header_quotation span {
	font-size: 15px;
}

.header_quotation img {
	width: 86px;
}


@media only screen and (max-width: 1281px) {

	.id-bg,
	.id-bg2 {
		padding: 10px 16px;
	}

	.verification-btn-disabled,
	.verification-btn,
	.support_button {
		width: 265px;
	}

	.submission-id-container {
		margin-left: 2.2rem;
	}

	.submission-text {
		width: 345px;
	}

	.get_supp_btn_wrap {
		width: 297px;
	}

	.get_supp_btn_space {
		width: 307px;
		margin-left: 2.2rem;
	}

	.resubmit-btn {
		margin: 0 0 0 3vw;
	}
}

@media only screen and (max-width: 1281px) and (min-width: 1200px) and (max-height: 721px) and (min-height: 700px) {

	.heading p,
	.heading p {
		margin-bottom: 1rem;
	}

	.faq {
		margin-top: 20px;
	}

	.faq h2,
	.heading h2 {
		margin-bottom: 18px;
	}

	#sidebar {
		max-height: calc(100vh - 180px);
	}

	/* .header-container ~ .iframe {
		height: calc(117vh - 66px);
	} */
}

@media only screen and (max-width: 1000px) {
	.logo_wrap {
		display: flex;
		justify-content: center;
	}

	.container-middle {
		display: none;
	}

	.main-container {
		flex-wrap: nowrap;
		height: auto;
		flex-direction: column;
	}

	.container-right {
		margin-top: 48px;
	}

	/* .header-container {
		display: block;
	} */
	.top_bar_quotes {
		align-items: center;
		justify-content: center;
	}

	.header_quotation:first-child {
		margin-left: 0;
	}

	.header_quotation:first-child:before {
		background-color: transparent;
	}

	.header_quotation {
		min-width: 30%;
		max-width: 300px;
	}

	.confirmation-box {
		min-width: 560px;
	}

	.support_button {
		margin-top: 30px;
	}
}

@media only screen and (max-width: 768px) {
	.sidebar_container_wrapper {
		flex-direction: column;
	}

	#sidebar:not(.collapsed) {
		width: 100%;
	}

	.collumn-faq,
	.collumn-faq+.collumn-faq {
		width: 50%;
	}

	#sidebar .overflow {
		border: 0;
	}

	.collumn-faq {
		padding-right: 20px;
	}

	.collumn-faq+.collumn-faq {
		padding: 0 0 0 20px;
	}

	#sidebar .overflow {
		padding-right: 20px;
	}

	.iframe_wrapper #sidebar .overflow {
		overflow: visible;
		height: auto;
	}

	.heading h2 {
		font-size: 1.1rem !important;
	}

	.faq h2 {
		font-size: 1.1rem !important;
	}

	.content_inner {
		max-height: none;
	}

	.submission_id_wrap {
		margin-top: 30px;
		width: 100%;
	}

	.accordion-button::after {
		margin: 0;
		width: 0;
	}

	.main-container2 {
		margin-left: 0;
		margin-top: 0;
	}

	.get_supp_btn_wrap {
		margin: 0 auto;
	}

	.support-container {
		max-width: 85%;
	}

	.support-container .accordion-container {
		gap: 30px;
	}

	.support-container .accordion-container>div {
		width: 50%;
	}

	.support-email,
	.support-input {
		margin-left: 0;
		width: 100%;
	}

	.support-container .accordion-item2 {
		width: 100%;
	}

	.foot-logo-wrap {
		margin-top: 36px;
	}

	.faq_quote {
		height: auto;
		max-height: none;
	}

	.header-container {
		padding: 0 1rem;
		margin: -3.5rem -1rem 20px;
	}

	.get_supp_btn_wrap+div {
		display: none;
	}

	.iframe_wrapper #sidebar .nav-btn+div {
		margin-left: 5px;
		max-height: calc(100vh - 154px);
		overflow: auto;
	}

	.accordion-button {
		justify-content: flex-start;
	}

	.iframe_wrapper .support_button {
		width: 100%;
		margin: 18px 0 30px !important;
		max-width: 230px;
	}
}

@media only screen and (max-width: 580px) {
	.parent {
		padding: 3.5rem 1rem;
	}

	.submission-id-container {
		margin-left: 0 !important;
	}

	.main-logo {
		height: 24px;
	}

	.confirmation-box {
		min-width: 1px;
		width: calc(100vw - 48px);
	}

	.confirmation-btn-container {
		flex-direction: column;
		align-items: center;
	}

	.confirmation-box,
	.container-left,
	.container-right {
		font-size: 22px;
		text-align: center;
	}

	.support-container .accordion-container {
		flex-direction: column;
		gap: 0;
	}

	.support-container .accordion-container>div {
		width: 100%;
	}

	.header_quotation {
		padding: 0 0 0 16px;
	}

	.header_quotation,
	.header_quotation span {
		font-size: 12px;
		width: auto;
	}

	.header_quotation img {
		width: 64px;
	}

	.header_quotation .diro-logo-trans {
		width: 24px;
	}

	.top_bar_quotes>p {
		width: 60%;
	}

	.top_bar_quotes>div {
		width: 40%;
	}

	.top_bar_quotes {
		max-width: 300px;
	}
}

@media screen and (max-width: 500px) {
	.heading p {
		font-size: 0.9rem !important;
	}

	.accordion-button {
		font-size: 0.9rem !important;
	}
}

@media only screen and (width: 1920px) {
	.header-container~.iframe {
		width: calc(120% + 28px);
		right: calc(10% - 13px);
	}
}

@media only screen and (width: 1680px) {
	.header-container~.iframe {
		width: calc(120% + 14px);
		right: calc(120% + 17px);
	}
}

@media only screen and (width: 1600px) {
	.header-container~.iframe {
		width: calc(120% + 10px);
		right: calc(10% - 22px);
	}
}

@media only screen and (width: 1400px) {
	.header-container~.iframe {
		width: calc(120% + 2px);
	}
}

@media only screen and (width: 1280px) {
	.header-container~.iframe {
		width: calc(120% + -2px);
		right: calc(10% - 28px);
	}
}

@media only screen and (width: 1152px) {
	.header-container~.iframe {
		width: calc(120% + -2px);
	}
}

@media only screen and (max-height: 1081px) {
	.header-container~.iframe {
		height: calc(120% - 68px);
		bottom: calc(10% + 15px);
	}
}

@media only screen and (max-height: 1051px) {
	.header-container~.iframe {
		height: calc(120% - 70px);
		bottom: calc(10% + 13px);
	}
}

@media only screen and (max-height: 1025px) {
	.header-container~.iframe {
		height: calc(120% - 75px);
		bottom: calc(10% + 13px);
	}
}

@media only screen and (max-height: 981px) {
	.header-container~.iframe {
		height: calc(120% - 74px);
		bottom: calc(10% + 12px);
	}
}

@media only screen and (max-height: 801px) {
	.header-container~.iframe {
		height: calc(120% - 82px);
		bottom: calc(10% + 9px);
	}
}

@media only screen and (max-height: 769px) {
	.header-container~.iframe {
		height: calc(120% - 80px);
		bottom: calc(10% + 8px);
	}
}

/* @media only screen and (max-height: 769px) and (min-height: 700px) {
	#sidebar {
		max-height: calc(100vh - 170px);
	}
} */

@media only screen and (max-height: 721px) {
	.header-container~.iframe {
		height: calc(120% - 84px);
		bottom: calc(10% + 6px);
	}
}

/* @media only screen and (max-width: 800px) {
	.container-left,
	.container-right {
		margin-top: 20%;
	}
} */

@media (max-width:912px) {
	.h-adj{
		height: max-content!important;
	}
}

::-webkit-scrollbar {
  width: 2px;
}




/* Track */
/* ::-webkit-scrollbar-track {
  background: #f1f1f1;
} */

/* Handle */
::-webkit-scrollbar-thumb {
  /* background:  var(--dark-yellow);
   */
   background: black;
  border-radius: 50px;
  cursor: pointer;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #C4C4C4;
  cursor: pointer;
}

.scrollbar-text {
	direction: ltr;
}

.form > input:focus {
	box-shadow: none!important; 
}

@media screen and (min-width: 750px) and (max-width: 768px) and (min-height: 1016px) and (max-height: 1024px) {
  
	.form > input:focus {
		box-shadow: none!important; 
	}
	::-webkit-scrollbar {
		width: 2px;
	}
}

@media screen and (min-width: 800px)  and (min-height: 1160px)  {
	.scrollbar-area {
	  direction: ltr;
	}
  
	.scrollbar-text {
	  direction: ltr;
	}
  
	.form > input:focus {
	  box-shadow: none !important; 
	}

  }

  
  


/* 
@media screen and (max-width: 768px) and (max-height: 1024px) {
	

	.scrollbar-area {
		direction: rtl;
		transform: translate(-35.25%, 0%);
	}
  
	.scrollbar-text {
		direction: ltr;
	}
  
	.form > input:focus {
		box-shadow: none!important; 
	}
	::-webkit-scrollbar {
		width: 200px;
	  }
}


@media screen and (max-width: 820px) and (max-height:1024px) {
	
	.ipad-air{
		margin-left: 0px !important ;
		margin-right: 10px !important;
	}

} */
/* rishabh */

/* .iframe-element {
	transform: scale(0.8);
	width: 125%;
	height: 125%;
	transform-origin: 0 0; 
} */
.iframe-element {
	transform: scale(.80);
	width: 121.09%;
	height: 113.5%;
	transform-origin: 0 0;
}
@media (max-width: 767px) {
    .iframe-element {
        transform: scale(.72);
        width: 139.22%;
        height: 129%;
        transform-origin: 0 0;
    }
}


.anchor{
	text-decoration: none!important;
	margin-bottom: 10px!important;
	border-bottom: 1.5px solid #0d6efd!important;
	color: #0d6efd!important;
}
.black-anchor{
	text-decoration: none!important;
	margin-bottom: 10px!important;
	border-bottom: 1.5px solid black!important;
	color: black!important;
}

.cursor-pointer {
	cursor: pointer;
  }

.supportmodal{
  position: fixed;
  overflow: hidden;
}


.custom-accordion .accordion-button.collapsed {
	box-shadow: none;
  }


.hover-cursor{
	cursor: pointer;
}

.status-style::after{

	content: 'Document under process!! ';
	display: none;
	position: absolute; /* Adjust the positioning as needed */
	background-color: #120c0c;
	/* padding: 5px; */
	top: 220px;
	left:220px;
	color: #fff; 
	border: 1px solid #dccece;
	border-radius: 2px;
	/* box-shadow: 0 2px 4px rgba(0, 0, 0, 100); */

}

.status-style:hover::after {
	display: inline-block;
  }
  .margin-auto{
	margin-left: auto ;
	margin-right: auto;
  }
  
  
  /* mobile warning */

  .desktop-optimized {
	background: orange;
    bottom: 0;
    display: none;
    font-size: 13px;
    font-weight: 500;
    height: 55px;
    left: 0;
    margin-top: 25px;
    padding: 5px 25px;
    position: fixed;
    width: 100%;
    z-index: 12;
}

.desktop-optimized p {
	margin-right: 15px;
}



@media only screen and (max-width: 580px) {

	.desktop-optimized {
		display: block;
	}

	
	

}
@media only screen and (min-width: 580px) {

	
	.alert-container {
		display: none;
	}
	

}
/* laptop */
@media only screen and (min-width:  1024px) {

	::-webkit-scrollbar-thumb:hover {
		background: #C4C4C4;
		cursor: pointer;
	  }
	  .scrollbar-area {
		  direction: rtl;
		  transform: translate(-18.3%, 0%);	
	  }
	  
	  .scrollbar-text {
		  direction: ltr;
		  transform: translate(10.50%,0%);
	  }
	  
	  .form > input:focus {
		  box-shadow: none!important; 
	  }

}


/* Laptop1 */
@media only screen and (min-width:  1500px) {

	::-webkit-scrollbar-thumb:hover {
		background: #C4C4C4;
		cursor: pointer;
	  }
	  .scrollbar-area {
		  direction: rtl;
		  transform: translate(-17.3%, 0%);	
	  }
	  
	  .scrollbar-text {
		  direction: ltr;
		  transform: translate(10.50%,0%);
	  }
	  
	  .form > input:focus {
		  box-shadow: none!important; 
	  }

}
/* desktop */
@media only screen and (min-width: 1900px) {

	::-webkit-scrollbar-thumb:hover {
		background: #C4C4C4;
		cursor: pointer;
	  }
	  /* Specific Chnage for Abillio */
	  .scrollbar-area {
		  direction: rtl;
		  transform: translate(-15.75%, 0%);	
	  }
	  
	  .scrollbar-text {
		  direction: ltr;
		  transform: translate(10.50%,0%);
	  }
	  
	  .form > input:focus {
		  box-shadow: none!important; 
	  }


	

}



.close-mobile-warning {
	position: absolute;
	display: flex;
	align-items: center;
	position: absolute;
	right: 0px;
	top: 0px;
	width: 30px;
	height: 9px;
	opacity: 0.7;
	cursor: pointer;
}

.close-mobile-warning:hover {
	opacity: 1;
}

.close-mobile-warning:before,
.close-mobile-warning:after {
	position: absolute;
	left: 15px;
	content: " ";
	height: 15px;
	width: 3px;
	background-color: #111;
	transform: rotate(-45deg);
}

.close-mobile-warning:before {
	transform: rotate(45deg);
}

.blur-mobile {
	-webkit-backdrop-filter: blur(4px) saturate(100%) contrast(60%) brightness(130%);
}



.custom-orange-icon {
	color: #1334d1 !important;
}


.overflow-scroll {
    overflow-y: auto;
	max-height: 74.5vh;   
} 

@media (max-width: 580px) {
	.overflow-scroll {
	  height: 45vh; 
	}
}

@media (max-width: 992px) and (min-width: 582px) {
	.overflow-scroll {
		height: 59vh; 
	  }
}

@media (max-width:900px) and (min-width:700px ) {
	body{
		overflow: auto !important ;
	}
	
}



@media  (max-width: 992px) and (min-width: 912px) {
	body {
		overflow: auto !important; /* Override body's overflow hidden */
	}
    ::-webkit-scrollbar-thumb:hover {
        background: #C4C4C4;
        cursor: pointer;
    }
    .scrollbar-area {
        direction: rtl;
        transform: translate(-9.75%, 0%);
    }
    .scrollbar-text {
        direction: ltr;
        transform: translate(10.50%, 0%);
    }
    .h-adj{
        height: 40vh!important;
    }
}



/* For scrollbaer on right side on that screen  */
@media (min-width:992px ) and (max-width:1073px) {


    .scrollbar-area {
        direction: rtl;
        transform: translate(-18.75%, 0%);
    }


    .scrollbar-text {
        direction: ltr;
        transform: translate(10.50%, 0%);
    }
   
}

/* for button css adjustment   */

.adjustment {
    width: 16.8rem;
	height: 3rem;
    font-weight: 600;
    font-size: 16px

}


/* 
@media (min-width: 991px) and (max-width: 1222px) {

    .adjustment{
        width: 15.0rem !important;
		height: 3rem;
        font-weight: 600;
        font-size: 16px
    }
} */


@media (min-width: 992px) and (max-width: 1110px) {

	.adjustment{
		width: 13.0rem !important;
		height: 3.3rem;
		font-weight: 600;
        font-size: 16px

	}
}
@media (min-width: 1109px) and (max-width: 1222px) {

	.adjustment{
		width: 15.0rem !important;
		height: 3.3rem;
		font-weight: 600;
        font-size: 16px

	}

	
}

.powered-by-diro{
	width: 11.7rem;
	margin-left: 3.2rem;
	margin-top: 1rem;
}

@media (min-width: 992px) and (max-width: 1110px) {

    .powered-by-diro{
		width: 7.96rem;
		margin-left: 3.2rem;
		margin-top: 1rem
	}
}

@media (min-width: 1109px) and (max-width: 1222px) {

    .powered-by-diro{
		width: 7.96rem;
		margin-left: 5.2rem;
		margin-top: 1rem
	}
}
